@use '../base/vars' as vars;
/* Hero */
.hero {
  position: relative;
  margin-bottom: 50px;
  overflow: hidden;

  .container {
    position: relative;
    overflow: hidden;
  }

  &__content {
    padding: 100px 80px;
    @include flex-container;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    z-index: 2;
    background: none;
    pointer-events: none;
  }

  &__title {
    margin: 0;
    margin-bottom: 24px;
    font-size: 70px;
    font-weight: 800;
    max-width: 837px;
    line-height: 85px;
    color: $color-item-0;
    color: var(--color-item-0);
  }

  &__descr {
    margin: 0;
    margin-bottom: 90px;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    max-width: 790px;
    color: $color-item-0;
    color: var(--color-item-0);
  }

  &__smeta {
    font-size: 23px;
    font-weight: 700;
    color: $color-item-0;
    color: var(--color-item-0);
    background-color: $color-item-6;
    background-color: var(--color-item-6);
    padding: 25px 39px;
    line-height: 29px;
    pointer-events: auto;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    text-align: center;
    outline: none;
    @include transition(background-color, 0.2s, linear);

    &:hover,
    &:focus {
      background-color: $color-item-7;
      background-color: var(--color-item-7);
    }
    &:active {
      background-color: $color-item-8;
      background-color: var(--color-item-8);
    }
  }

  &__swiper {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 22px;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;

    &-cont {
      position: absolute;
      top: 0;
      width: calc(100% - (var(--container) * 2));
      height: 100%;
      z-index: 1;
    }

    &-wrapper {
      @include flex-container;
      height: 100%;
      @include transition(transform, 0.3s, ease);
      will-change: transform;
    }

    &-slide {
      width: 100%;
      flex-shrink: 0;
      @include flex-container;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      // Фолбэк для браузеров, не поддерживающих WebP
      &:nth-child(1) {
        background: url(../img/hero__bg-img-1.png) no-repeat center/cover;
        background-image: -webkit-image-set(
          url(../img/hero__bg-img-1.png) 1x,
          url(../img/hero__bg-img-1.webp) 1x
        );
        background-image: image-set(
          url(../img/hero__bg-img-1.png) 1x,
          url(../img/hero__bg-img-1.webp) 1x
        );
      }
      &:nth-child(2) {
        background: url(../img/hero__bg-img-2.png) no-repeat center/cover;
        background-image: -webkit-image-set(
          url(../img/hero__bg-img-2.png) 1x,
          url(../img/hero__bg-img-2.webp) 1x
        );
        background-image: image-set(
          url(../img/hero__bg-img-2.png) 1x,
          url(../img/hero__bg-img-2.webp) 1x
        );
      }
      &:nth-child(3) {
        background: url(../img/hero__bg-img-3.png) no-repeat center/cover;
        background-image: -webkit-image-set(
          url(../img/hero__bg-img-3.png) 1x,
          url(../img/hero__bg-img-3.webp) 1x
        );
        background-image: image-set(
          url(../img/hero__bg-img-3.png) 1x,
          url(../img/hero__bg-img-3.webp) 1x
        );
      }
    }

    &-pagination {
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      display: flex;
      gap: 20px;

      &-bullet {
        width: 15px;
        height: 15px;
        background-color: #fff;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border: none;
        cursor: pointer;
        opacity: 0.6;
        @include transition(opacity, 0.2s, ease-in-out);

        &:hover,
        &:focus,
        &:active {
          opacity: 0.8;
        }

        &.active {
          opacity: 1;
        }
      }
    }
  }

  // Responsive стили
  @media (max-width: 1130px) {
    margin-bottom: 35px;

    &__title {
      font-size: 60px;
      line-height: 73px;
      margin-bottom: 16px;
    }

    &__descr {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 60px;
    }

    &__swiper {
      border-radius: 32px;
      -webkit-border-radius: 32px;
      -moz-border-radius: 32px;

      &-cont {
        width: calc(100% - (var(--container) * 2));
      }
    }

    &__content {
      padding: 160px 77px;
    }
  }

  @media (max-width: 920px) {
    margin-bottom: 35px;

    &__content {
      padding: 160px 40px;
    }
    &__title {
      font-size: 50px;
      line-height: 60px;
    }
    &__descr {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 61px;
    }
  }

  @media (max-width: 740px) {
    margin-bottom: 60px;

    &__title {
      font-size: 20px;
      line-height: normal;
      margin-bottom: 6px;
    }

    &__content {
      padding: 60px 20px 60px 21px;
      align-items: center;
      text-align: center;
    }

    &__swiper {
      border-radius: 17px;
      -webkit-border-radius: 17px;
      -moz-border-radius: 17px;

      &-cont {
        width: calc(100% - (var(--container) * 2));
      }

      &-pagination {
        bottom: 15px;

        &-bullet {
          width: 10px;
          height: 10px;
        }
      }
    }

    &__descr {
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 33px;
    }

    &__smeta {
      padding: 13px 28px;
      font-size: 12px;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
    }
  }
}
