:root {
  --color-item-0: #fff;
  --color-item-1: #000;
  --color-item-2: #222;
  --color-item-3: #333;
  --color-item-4: #666;
  --color-item-5: #999;
  --color-item-6: #ff9900;
  --color-item-7: #ffb84d;
  --color-item-8: #e1670e;
  --color-item-9: #f7b045;
  --color-item-10: #414141;
  --color-item-11: #7c7c7c;
  --color-item-12: #cacaca;
  --color-item-13: #ececec;
  --color-item-14: #b7b7bf;
  --color-item-15: #e5e5e5;
  --size-standart: 17.7px;
  --size-working: 142px;
  --size-footer: 236px;
  --container: 75px;
  @media (max-width: 1130px) {
    --container: 50px;
  }
  @media (max-width: 740px) {
    --container: 15px;
  }
}

// 1. Определяем SCSS-переменные (fallback для браузеров без поддержки CSS-переменных)
$color-item-0: #fff;
$color-item-1: #000;
$color-item-2: #222;
$color-item-3: #333;
$color-item-4: #666;
$color-item-5: #999;
$color-item-6: #ff9900;
$color-item-7: #ffb84d;
$color-item-8: #e1670e;
$color-item-9: #f7b045;
$color-item-10: #414141;
$color-item-11: #7c7c7c;
$color-item-12: #cacaca;
$color-item-13: #ececec;
$color-item-14: #b7b7bf;
$color-item-15: #e5e5e5;

// 2. Миксины для кроссбраузерных переходов и flex‑контейнера
@mixin transition($property, $duration: 0.2s, $timing: ease-in-out) {
  -webkit-transition: $property $duration $timing;
  -moz-transition: $property $duration $timing;
  -o-transition: $property $duration $timing;
  transition: $property $duration $timing;
}

@mixin flex-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

// 4. Миксин для grid-раскладки с fallback на flex
@mixin grid-layout($columns: 2, $gap: 30px) {
  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat($columns, 1fr);
    gap: $gap;
  }
  @supports not (display: grid) {
    @include flex-container;
    flex-wrap: wrap;
    gap: $gap;
    > * {
      width: calc(100% / #{$columns});
    }
  }
}
