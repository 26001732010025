@use '../base/vars' as vars;

.how {
  margin-bottom: 111px;

  &__title {
    margin: 0 0 50px;
    /* Фолбэк для старых браузеров */
    color: $color-item-1;
    color: var(--color-item-1);
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__nav {
    @include flex-container;
    align-items: center;
    gap: 86px;
    margin-bottom: 46px;

    @supports not (gap: 86px) {
      > * {
        margin-right: 86px;
      }
      > *:last-child {
        margin-right: 0;
      }
    }

    &-item {
      padding: 0;

      &-btn {
        cursor: pointer;
        /* Фолбэк значения цвета */
        color: $color-item-1;
        color: var(--color-item-1);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        outline: 2px solid transparent;
        outline-offset: 2px;
        @include transition(all, 0.5s);

        &:hover {
          color: $color-item-6;
          color: var(--color-item-6);
        }
        &:focus {
          color: $color-item-1;
          color: var(--color-item-1);
          outline-color: $color-item-6;
          outline-color: var(--color-item-6);
        }
        &:active {
          color: $color-item-8;
          color: var(--color-item-8);
          outline-color: transparent;
        }
        &.active {
          color: $color-item-8;
          color: var(--color-item-8);
          outline-color: transparent;
        }
      }
    }
  }

  &__page {
    /* Если браузер поддерживает Grid – используем его, иначе fallback на flex */
    @supports (display: grid) {
      @include grid-layout(2, 30px);
    }
    @supports not (display: grid) {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
    }
    opacity: 1;
    @include transition(all, 0.5s);

    &:not(.active) {
      opacity: 0;
      display: none;
    }

    &-btn-list {
      @include flex-container;
      flex-wrap: wrap;
    }

    &-img {
      height: 100%;
      padding-top: 10px;
    }

    &-title {
      margin: 0;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 8px;
    }

    &-descr {
      margin: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 32px;
      font-style: normal;
    }

    &-btn {
      cursor: pointer;
      padding: 19px 37px;
      border: 1px solid transparent;
      border-radius: 15px;
      line-height: normal;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      @include transition(all, 0.5s);
      outline: none;

      &:first-child {
        color: $color-item-0;
        color: var(--color-item-0);
        font-weight: 700;
        background-color: $color-item-6;
        background-color: var(--color-item-6);
        border-color: $color-item-6;
        border-color: var(--color-item-6);

        &:hover,
        &:focus {
          background-color: $color-item-6;
          background-color: var(--color-item-6);
          border-color: $color-item-6;
          border-color: var(--color-item-6);
        }
        &:active {
          background-color: $color-item-8;
          background-color: var(--color-item-8);
          border-color: $color-item-8;
          border-color: var(--color-item-8);
        }
      }

      &:last-child {
        color: $color-item-1;
        color: var(--color-item-1);
        font-weight: 400;
        border-color: $color-item-1;
        border-color: var(--color-item-1);

        &:hover,
        &:focus {
          background-color: $color-item-4;
          background-color: var(--color-item-4);
          border-color: $color-item-4;
          border-color: var(--color-item-4);
          color: $color-item-0;
          color: var(--color-item-0);
        }
        &:active {
          background-color: $color-item-1;
          background-color: var(--color-item-1);
          border-color: $color-item-1;
          border-color: var(--color-item-1);
          color: $color-item-0;
          color: var(--color-item-0);
        }
      }
    }

    &-btn-list {
      @include flex-container;
      align-items: center;
      gap: 32px;
    }

    &-text {
      border-radius: 30px;
      @include flex-container;
      flex-direction: column;
      max-width: 758px;
      justify-content: space-between;
    }
  }

  @media (max-width: 1130px) {
    margin-bottom: 81px;

    &__title {
      font-size: 48px;
      line-height: 59px;
      margin-bottom: 50px;
    }
    &__nav {
      margin-bottom: 50px;
    }

    &__page {
      &-img {
        padding-top: 12px;
        padding-left: 10px;
      }
      &-text {
        gap: 25px;
      }
    }
  }

  @media (max-width: 920px) {
    margin-bottom: 60px;

    &__nav {
      @include flex-container;
      justify-content: space-between;
      gap: 8px;
    }

    &__title {
      font-weight: 400;
      font-size: 48px;
      line-height: 59px;
      margin-bottom: 48px;
    }

    &__page {
      @include flex-container;
      flex-direction: column-reverse;
      gap: 39px;

      &-img {
        padding: 0;
        max-height: 284px;
        width: 100%;
      }
    }
    br {
      display: none;
    }
  }

  @media (max-width: 480px) {
    margin-bottom: 59px;

    &__title {
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 20px;
    }
    &__nav {
      margin-bottom: 16px;
      &-item {
        &-btn {
          font-size: 14px;
        }
      }
    }

    &__page {
      gap: 22px;

      &-title {
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        margin-bottom: 8px;
      }
      &-descr {
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        margin-bottom: 0;
      }
      &-btn {
        border-radius: 10px;

        &:first-child {
          padding: 11px 43px;
          font-size: 12px;
        }
        &:nth-child(2) {
          padding: 12px 31px;
          font-size: 12px;
        }
      }

      &-btn-list {
        @include flex-container;
        align-items: center;
        justify-content: space-between;
        gap: 9px;
      }
    }
  }
}
