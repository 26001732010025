@font-face {
	font-family: Montserrat;
	font-display: swap;
	src: url("../fonts/Montserrat-Bold.woff2") format("woff2"), url("../fonts/Montserrat-Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: Montserrat;
	font-display: swap;
	src: url("../fonts/Montserrat-Regular.woff2") format("woff2"), url("../fonts/Montserrat-Regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: Montserrat;
	font-display: swap;
	src: url("../fonts/Montserrat-ExtraBold.woff2") format("woff2"), url("../fonts/Montserrat-ExtraBold.woff") format("woff");
	font-weight: 800;
	font-style: normal;
}
@font-face {
	font-family: Montserrat;
	font-display: swap;
	src: url("../fonts/Montserrat-SemiBold.woff2") format("woff2"), url("../fonts/Montserrat-SemiBold.woff") format("woff");
	font-weight: 600;
	font-style: normal;
}
