@use '../base/vars' as vars;
/* Header */
.header {
  box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.1);
  padding: 12px 0 14px 0;
  margin-bottom: 50px;
  border-radius: 0 0 30px 30px;
  -webkit-border-radius: 0 0 30px 30px;
  -moz-border-radius: 0 0 30px 30px;

  .container {
    @include flex-container;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  &__burger {
    display: none;
  }

  &__logo {
    @include flex-container;
    align-items: center;
    justify-content: center;
    max-width: 225px;
    width: 100%;
    height: 100%;
    outline: 2px solid transparent;
    outline-offset: 5px;
    padding-top: 2px;
    @include transition(all, 0.5s, ease-in-out);
    &:focus {
      outline-color: var(--color-item-6);
    }
  }

  &__nav {
    @include flex-container;
    align-items: center;
    justify-content: space-between;

    &-cont {
      @include flex-container;
      justify-content: space-between;
    }

    &-list {
      @include flex-container;
      list-style: none;
      padding: 0;
      gap: 85.5px;
    }

    &-item {
      font-size: 18px;
      font-weight: 600;
      a {
        border-bottom: 2px solid transparent;
        outline: 2px solid transparent;
        outline-offset: 2px;
        @include transition(all, 0.5s, ease-in-out);
        &:hover {
          border-color: var(--color-item-6);
        }
        &:focus {
          outline-color: var(--color-item-6);
        }
        &:active {
          border-color: var(--color-item-8);
          outline-color: transparent;
          color: var(--color-item-8);
        }
      }
    }
  }

  &__search {
    opacity: 1;
    position: absolute;
    z-index: 18;
    background-color: var(--color-item-0);
    right: calc(var(--container) - 32px);
    top: -100px;
    @include flex-container;
    justify-content: space-between;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);
    padding: 18px 32px;
    align-items: center;
    border-radius: 24px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    width: 100%;
    max-width: 400px;
    @include transition(all, 0.5s, ease-in-out);
    gap: 4px;

    &.active {
      -webkit-transform: translateY(105px);
      -moz-transform: translateY(105px);
      transform: translateY(105px);
    }

    &-input {
      border: none;
      color: var(--color-item-12);
      padding-bottom: 5px;
      border-bottom: 1px solid var(--color-item-12);
      outline: none;
      width: 80%;
    }

    &-icon {
      outline: 2px solid transparent;
      outline-offset: 2px;
      padding: 0;
      background: transparent;
      cursor: pointer;
      @include flex-container;
      transition: all 0.5s ease-in-out;
      svg {
        @include transition(all, 0.5s, ease-in-out);
        rect {
          @include transition(all, 0.5s, ease-in-out);
          fill: var(--color-item-1);
        }
        path {
          @include transition(all, 0.5s, ease-in-out);
          stroke: var(--color-item-1);
        }
      }
      &:focus {
        outline-color: var(--color-item-6);
        svg {
          rect {
            fill: var(--color-item-1);
          }
          path {
            stroke: var(--color-item-1);
          }
        }
      }
      &:hover {
        svg {
          path {
            stroke: var(--color-item-6);
          }
          rect {
            fill: var(--color-item-6);
          }
        }
      }
    }

    &-close {
      svg {
        rect {
          fill: var(--color-item-12);
        }
        circle {
          @include transition(all, 0.5s, ease-in-out);
        }
      }
      &:hover {
        svg {
          rect {
            fill: var(--color-item-6);
          }
          circle {
            stroke: var(--color-item-6);
          }
        }
      }
      &:focus {
        outline-color: var(--color-item-6);
        svg {
          rect {
            fill: var(--color-item-12);
          }
          circle {
            stroke: var(--color-item-12);
          }
        }
      }
      &:active {
        svg {
          rect {
            fill: var(--color-item-1);
          }
          circle {
            stroke: var(--color-item-1);
          }
        }
      }
    }
  }

  @media (max-width: 1130px) {
    padding: 32px 0 26px 0;
    margin-bottom: 40px;
    &__search.active {
      -webkit-transform: translateY(95px);
      -moz-transform: translateY(95px);
      transform: translateY(95px);
    }
    &__nav {
      position: absolute;
      left: -273px;
      margin: 0;
      top: 0;
      z-index: 10;
      background-color: var(--color-item-0);
      display: block;
      padding: 80px 55px 55px 55px;
      border-radius: 0 0 32px 0;
      -webkit-border-radius: 0 0 32px 0;
      -moz-border-radius: 0 0 32px 0;
      @include transition(transform, 0.5s, ease);
      &-list {
        flex-direction: column;
        gap: 60px;
      }
      .open & {
        -webkit-transform: translateX(273px);
        -moz-transform: translateX(273px);
        transform: translateX(273px);
        &-list {
          display: flex;
          margin: 0;
        }
        &-item {
          margin: 0;
          a {
            font-size: 24px;
          }
        }
      }
    }
    &__logo {
      max-width: 150px;
    }
    &__burger {
      margin-top: 3px;
      width: 30px;
      height: 24px;
      @include flex-container;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      align-self: center;
      z-index: 16;
      border: none;
      span {
        position: absolute;
        top: 54%;
        left: 3%;
        width: 30px;
        height: 3px;
        background-color: #000;
        -webkit-transform: translateX(-30%);
        -moz-transform: translateX(-30%);
        transform: translateX(-30%);
        @include transition(all, 0.5s, ease);
        &:nth-child(1) {
          -webkit-transform: translateY(-14px);
          -moz-transform: translateY(-14px);
          transform: translateY(-14px);
        }
        &:nth-child(2) {
          -webkit-transform: translateY(-4px);
          -moz-transform: translateY(-4px);
          transform: translateY(-4px);
        }
        &:nth-child(3) {
          -webkit-transform: translateY(6px);
          -moz-transform: translateY(6px);
          transform: translateY(6px);
        }
      }
      .open & span {
        &:nth-child(1) {
          -webkit-transform: translateY(-4px) rotate(45deg);
          -moz-transform: translateY(-4px) rotate(45deg);
          transform: translateY(-4px) rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          -webkit-transform: translateY(-4px) rotate(-45deg);
          -moz-transform: translateY(-4px) rotate(-45deg);
          transform: translateY(-4px) rotate(-45deg);
        }
      }
    }
  }

  @media (max-width: 920px) {
    margin-bottom: 40px;
    padding: 10px 0;
    border-radius: 0 0 15px 15px;
    -webkit-border-radius: 0 0 15px 15px;
    -moz-border-radius: 0 0 15px 15px;
    .open .header__nav {
      -webkit-transform: translateX(273px);
      -moz-transform: translateX(273px);
      transform: translateX(273px);
    }
  }

  @media (max-width: 500px) {
    .header__search.active {
      -webkit-transform: translateY(75px);
      -moz-transform: translateY(75px);
      transform: translateY(75px);
    }
    margin-bottom: 30px;
    padding: 16px 0;
    &__logo {
      width: 100%;
      max-width: 110px;
    }
    &__search {
      left: 0;
      right: 0;
      max-width: 100%;
      top: -90px;
      opacity: 1;
      display: flex;
      width: 100%;
      border-radius: 0 0 15px 15px;
      -webkit-border-radius: 0 0 15px 15px;
      -moz-border-radius: 0 0 15px 15px;
      padding: 22px 15px;
      @include transition(all, 0.5s, ease-in-out);
      &-input {
        width: 70%;
      }
    }
    &__nav {
      width: 100%;
      left: -100%;
      padding: 50px 0 50px 20px;
      &-list {
        gap: 32px;
      }
    }
    .open &__nav {
      -webkit-transform: translateX(100%);
      -moz-transform: translateX(100%);
      transform: translateX(100%);
    }
  }
}
