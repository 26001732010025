@charset "UTF-8";
/* Base */ /* Reset and base styles  */
* {
  padding: 0px;
  margin: 0px;
  border: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Links */
a,
a:link,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

/* Common */
aside,
nav,
footer,
header,
section,
main {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-size: inherit;
  font-weight: inherit;
}

ul,
ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

address {
  font-style: normal;
}

/* Form */
input,
textarea,
button,
select {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
}

input::-ms-clear {
  display: none;
}

button,
input[type=submit] {
  display: inline-block;
  box-shadow: none;
  background-color: transparent;
  background: none;
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

label {
  cursor: pointer;
}

legend {
  display: block;
}

.btn-reset {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.btn-reset {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.none {
  display: none !important;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

a {
  text-decoration: none;
  color: inherit;
}

:root {
  --color-item-0: #fff;
  --color-item-1: #000;
  --color-item-2: #222;
  --color-item-3: #333;
  --color-item-4: #666;
  --color-item-5: #999;
  --color-item-6: #ff9900;
  --color-item-7: #ffb84d;
  --color-item-8: #e1670e;
  --color-item-9: #f7b045;
  --color-item-10: #414141;
  --color-item-11: #7c7c7c;
  --color-item-12: #cacaca;
  --color-item-13: #ececec;
  --color-item-14: #b7b7bf;
  --color-item-15: #e5e5e5;
  --size-standart: 17.7px;
  --size-working: 142px;
  --size-footer: 236px;
  --container: 75px;
}
@media (max-width: 1130px) {
  :root {
    --container: 50px;
  }
}
@media (max-width: 740px) {
  :root {
    --container: 15px;
  }
}

@font-face {
  font-family: Montserrat;
  font-display: swap;
  src: url("../fonts/Montserrat-Bold.woff2") format("woff2"), url("../fonts/Montserrat-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Montserrat;
  font-display: swap;
  src: url("../fonts/Montserrat-Regular.woff2") format("woff2"), url("../fonts/Montserrat-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Montserrat;
  font-display: swap;
  src: url("../fonts/Montserrat-ExtraBold.woff2") format("woff2"), url("../fonts/Montserrat-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: Montserrat;
  font-display: swap;
  src: url("../fonts/Montserrat-SemiBold.woff2") format("woff2"), url("../fonts/Montserrat-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
html {
  scroll-behavior: smooth;
}

body {
  font-family: "Montserrat", sans-serif;
}

.container {
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 var(--container);
}

.ac {
  margin-top: 8px;
  border: 1px solid #eee;
  background-color: #fff;
  box-sizing: border-box;
}

.ac .ac-header {
  margin: 0;
  padding: 0;
}

.ac .ac-trigger {
  color: #111;
  text-align: left;
  width: 100%;
  padding: 8px 32px 8px 8px;
  display: block;
  cursor: pointer;
  background-color: transparent;
  transition: color 0.25s ease;
  position: relative;
  text-decoration: none;
  margin: 0;
  border: 0;
}

.ac .ac-trigger::after {
  text-align: center;
  width: 15px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  position: absolute;
  right: 10px;
  top: 50%;
}

.ac .ac-trigger:focus {
  color: #8a8a8a;
}

.ac .ac-panel {
  overflow: hidden;
  transition-property: height, visibility;
  transition-timing-function: ease;
}

.ac .ac-panel .ac-text {
  color: #111;
  padding: 8px;
  margin: 0;
}

.ac.js-enabled .ac-panel {
  visibility: hidden;
}

.ac.is-active .ac-panel {
  visibility: visible;
}

.ac.is-active > .ac-header .ac-trigger::after {
  content: "–";
}

/* Blocks */
:root {
  --color-item-0: #fff;
  --color-item-1: #000;
  --color-item-2: #222;
  --color-item-3: #333;
  --color-item-4: #666;
  --color-item-5: #999;
  --color-item-6: #ff9900;
  --color-item-7: #ffb84d;
  --color-item-8: #e1670e;
  --color-item-9: #f7b045;
  --color-item-10: #414141;
  --color-item-11: #7c7c7c;
  --color-item-12: #cacaca;
  --color-item-13: #ececec;
  --color-item-14: #b7b7bf;
  --color-item-15: #e5e5e5;
  --size-standart: 17.7px;
  --size-working: 142px;
  --size-footer: 236px;
  --container: 75px;
}

@media (max-width: 1130px) {
  :root {
    --container: 50px;
  }
}
@media (max-width: 740px) {
  :root {
    --container: 15px;
  }
}
.about {
  margin-bottom: 100px;
}
.about__title {
  margin: 0 0 20px;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about__descr {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  flex-basis: 450px;
  margin-bottom: 50px;
  max-width: 1235px;
}
.about__content {
  display: flex;
  flex-wrap: wrap;
  gap: 32px 34px;
}
@supports (display: grid) {
  .about__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}
.about__content-item {
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  grid-column: span 1;
  gap: 9px;
}
.about__content-item-title {
  margin: 0;
  font-size: 24px;
  color: #333;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.about__content-item:first-child {
  width: 100%;
  grid-row: span 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: url(../../img/about__block-bg-img.png) no-repeat center/cover;
  background-image: -webkit-image-set(url(../../img/about__block-bg-img.png) 1x, url(../../img/about__block-bg-img.webp) 1x);
  background-image: image-set(url(../../img/about__block-bg-img.png) 1x, url(../../img/about__block-bg-img.webp) 1x);
  height: 100%;
  color: var(--color-item-0, #fff);
}
.about__content-item:first-child .about__content-item-descr {
  letter-spacing: 0;
  max-width: 435px;
}
.about__content-item-descr {
  margin: 0;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: -0.5px;
  font-style: normal;
  font-weight: 700;
}
.about__content-item-text {
  min-height: 196px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 32px;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  gap: 16px;
}
.about__content-item:not(:first-child) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--color-item-0, #fff);
  border: 1px solid var(--color-item-12, #ccc);
  border-radius: 32px;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  padding: 64px 60px 63px 297px;
  background-position: 57px center;
  background-repeat: no-repeat;
}
.about__content-item:nth-child(2) {
  background-image: url(../../img/about__block-icon-1.svg);
}
.about__content-item:nth-child(3) {
  background-image: url(../../img/about__block-icon-2.svg);
}
.about__content-item-btn {
  cursor: pointer;
  color: var(--color-item-0, #fff);
  background-color: transparent;
  border: 3px solid var(--color-item-6, #ff9900);
  padding: 18px 35px;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  outline: none;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  font-weight: 700;
}
.about__content-item-btn:hover, .about__content-item-btn:focus {
  border-color: var(--color-item-6, #ff9900);
  background-color: var(--color-item-6, #ff9900);
  color: var(--color-item-0, #fff);
}
.about__content-item-btn:active {
  border-color: var(--color-item-8, #cc6600);
  background-color: var(--color-item-8, #cc6600);
  color: var(--color-item-0, #fff);
}
.about__content-item-btn:focus {
  /* Альтернативный индикатор фокуса для доступности */
  outline: none;
  box-shadow: 0 0 0 3px rgba(255, 153, 0, 0.5);
}
@media (max-width: 1590px) {
  .about__content {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 304px) repeat(2, 295px);
    gap: 32px 0;
  }
  .about__content-item:first-child .about__content-item-descr {
    max-width: 660px;
  }
  .about__content-item-descr {
    max-width: 450px;
  }
  .about__content-item:not(:first-child) {
    padding: 64px 60px 63px 316px;
    background-position: 76px center;
    justify-content: start;
  }
}
@media (max-width: 1130px) {
  .about {
    margin-bottom: 80px;
  }
  .about__title {
    font-size: 48px;
    line-height: 59px;
  }
  .about__descr {
    padding: 0;
    max-width: 924px;
    width: 100%;
    margin-bottom: 60px;
  }
  .about__content {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 304px) repeat(2, 295px);
    gap: 32px 0;
  }
  .about__content-item-descr {
    max-width: 450px;
  }
  .about__content-item:not(:first-child) {
    padding: 64px 60px 63px 316px;
    background-position: 76px center;
    justify-content: start;
  }
}
@media (max-width: 920px) {
  .about__descr {
    margin-bottom: 55px;
  }
  .about__content {
    grid-template-rows: repeat(2, 309px) repeat(2, 235px);
  }
  .about__content-item-text {
    padding: 35px 40px 34px 40px;
  }
  .about__content-item:not(:first-child) {
    gap: 8px;
    padding: 34px 40px 35px 169px;
    background-size: 114px;
    background-position: 39px center;
  }
}
@media (max-width: 740px) {
  .about {
    margin-bottom: 60px;
  }
  .about__title {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 20px;
  }
  .about__descr {
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 14px;
    max-width: 100%;
    width: 100%;
  }
  .about__content {
    display: flex;
    flex-direction: column;
  }
  .about__content-item {
    border-radius: 0;
    padding: 0;
  }
  .about__content-item:first-child {
    background-size: contain;
    background-position: top;
    background-size: 100% 200px;
  }
  .about__content-item:first-child .about__content-item-descr {
    color: var(--color-item-1);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
  .about__content-item:not(:first-child) {
    padding: 61px 0 0 0;
    gap: 5px;
    border: none;
    background-position: 0 1px;
    background-size: 68px;
  }
  .about__content-item-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .about__content-item-descr {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    max-width: 100%;
    width: 100%;
  }
  .about__content-item-btn {
    padding: 10px 42px;
    color: var(--color-item-6);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-width: 2px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
  }
  .about__content-item-text {
    background: transparent;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    padding-top: 207px;
    align-items: start;
    gap: 12px;
  }
}

:root {
  --color-item-0: #fff;
  --color-item-1: #000;
  --color-item-2: #222;
  --color-item-3: #333;
  --color-item-4: #666;
  --color-item-5: #999;
  --color-item-6: #ff9900;
  --color-item-7: #ffb84d;
  --color-item-8: #e1670e;
  --color-item-9: #f7b045;
  --color-item-10: #414141;
  --color-item-11: #7c7c7c;
  --color-item-12: #cacaca;
  --color-item-13: #ececec;
  --color-item-14: #b7b7bf;
  --color-item-15: #e5e5e5;
  --size-standart: 17.7px;
  --size-working: 142px;
  --size-footer: 236px;
  --container: 75px;
}

@media (max-width: 1130px) {
  :root {
    --container: 50px;
  }
}
@media (max-width: 740px) {
  :root {
    --container: 15px;
  }
}
.faq {
  margin-bottom: 100px;
}
.faq__title {
  margin: 0 0 32px 0;
  color: #000;
  color: var(--color-item-1);
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.faq__item {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  border-bottom: 1px solid var(--color-item-12);
}
.faq__item:first-child {
  border-top: 1px solid var(--color-item-12);
}
.faq__item-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
}
.faq__item-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  background: none;
  padding: 30px 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  outline: none;
  gap: 16px;
}
.faq__item-header-title {
  color: #000;
  color: var(--color-item-1);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  text-align: start;
  line-height: 32px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.faq__item-header svg {
  min-width: 50px;
  min-height: 50px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.faq__item-header svg ellipse {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  fill: var(--color-item-12);
}
.faq__item-header svg path {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  fill: var(--color-item-1);
}
.faq__item-header:hover svg ellipse {
  fill: var(--color-item-6);
}
.faq__item-header:hover svg path {
  fill: var(--color-item-0);
}
.faq__item-header:hover .faq__item-header-title {
  color: var(--color-item-6);
}
.faq__item-header:focus svg ellipse {
  fill: var(--color-item-8);
}
.faq__item-header:focus svg path {
  fill: var(--color-item-0);
}
.faq__item-header:focus .faq__item-header-title {
  color: var(--color-item-8);
}
.faq__item-panel {
  overflow: hidden;
  max-height: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.faq__item-panel-text {
  margin: 0 0 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  gap: 32px;
  color: var(--color-item-1);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
.faq__item-panel-text:last-child {
  margin-bottom: 0;
}
.faq__item.active svg {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}
.faq__item.active .faq__item-panel {
  max-height: 1000px;
  margin-bottom: 40px;
}
@media (max-width: 1130px) {
  .faq {
    margin-bottom: 80px;
  }
  .faq__item-header-title {
    font-size: 20px;
    text-align: start;
  }
}
@media (max-width: 920px) {
  .faq {
    margin-bottom: 111px;
  }
  .faq__title {
    margin-bottom: 33px;
  }
}
@media (max-width: 740px) {
  .faq {
    margin-bottom: 60px;
  }
  .faq__title {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 16px;
  }
  .faq__item-header-title {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.17px;
  }
  .faq__item-header svg {
    min-width: 30px;
    max-width: 30px;
  }
  .faq__item-panel-text {
    font-size: 12px;
    line-height: 24px;
  }
}

:root {
  --color-item-0: #fff;
  --color-item-1: #000;
  --color-item-2: #222;
  --color-item-3: #333;
  --color-item-4: #666;
  --color-item-5: #999;
  --color-item-6: #ff9900;
  --color-item-7: #ffb84d;
  --color-item-8: #e1670e;
  --color-item-9: #f7b045;
  --color-item-10: #414141;
  --color-item-11: #7c7c7c;
  --color-item-12: #cacaca;
  --color-item-13: #ececec;
  --color-item-14: #b7b7bf;
  --color-item-15: #e5e5e5;
  --size-standart: 17.7px;
  --size-working: 142px;
  --size-footer: 236px;
  --container: 75px;
}

@media (max-width: 1130px) {
  :root {
    --container: 50px;
  }
}
@media (max-width: 740px) {
  :root {
    --container: 15px;
  }
}
.footer {
  /* Фолбэк для браузеров, не поддерживающих CSS-переменные */
  background-color: #333;
  background-color: var(--color-item-2, #333);
  padding: 50px 0;
}
.footer .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  color: var(--color-item-12, #ccc);
  gap: 32px;
}
.footer__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.footer__item:nth-child(1) {
  max-width: 252px;
  justify-content: space-between;
}
.footer__item:nth-child(2) {
  max-width: 570px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 420px;
  gap: 25px;
}
.footer__item:nth-child(3) {
  text-align: start;
  justify-self: center;
  align-self: center;
  max-width: 363px;
  color: var(--color-item-12, #ccc);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
.footer__item-logo {
  margin-bottom: 19px;
}
.footer__item-link a {
  color: var(--color-item-12, #ccc);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  outline: 1px solid transparent;
  outline-offset: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.footer__item-link a:hover {
  color: var(--color-item-6, #ff9900);
}
.footer__item-link a:focus {
  color: var(--color-item-12, #ccc);
  outline-color: var(--color-item-6, #ff9900);
}
.footer__item-link a:active {
  outline-color: transparent;
  color: var(--color-item-8, #cc6600);
}
.footer__item-link-list {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
}
.footer__item-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.footer__item-icon-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 25px;
}
.footer__item-icon a {
  border: none;
  outline: 1px solid transparent;
  outline-offset: 5px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.footer__item-icon a svg {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.footer__item-icon a svg path {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  fill: var(--color-item-11, #aaa);
}
.footer__item-icon a:hover svg {
  fill: var(--color-item-6, #ff9900);
}
.footer__item-icon a:hover svg path {
  fill: var(--color-item-6, #ff9900);
}
.footer__item-icon a:focus {
  outline-color: var(--color-item-6, #ff9900);
}
.footer__item-icon a:focus svg path {
  fill: var(--color-item-11, #aaa);
}
.footer__item-icon a:active {
  outline-color: transparent;
}
.footer__item-icon a:active svg {
  fill: var(--color-item-8, #cc6600);
}
.footer__item-icon a:active svg path {
  fill: var(--color-item-8, #cc6600);
}
.footer__item-form-title {
  margin: 0;
  font-weight: 400;
  font-size: 48px;
  line-height: 59px;
  text-align: start;
  width: 100%;
}
.footer__item-form-input {
  width: 100%;
  background-color: var(--color-item-10, #444);
  color: var(--color-item-12, #ccc);
  height: 60px;
  padding-left: 24px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border: none;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.footer__item-form-input:nth-child(4) {
  height: 150px;
  padding-top: 15px;
  line-height: 32px;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 7px;
}
.footer__item-form-input:hover, .footer__item-form-input:focus {
  background-color: var(--color-item-4, #555);
  color: var(--color-item-12, #ccc);
}
.footer__item-form-input:active {
  background-color: var(--color-item-4, #555);
  color: var(--color-item-0, #fff);
}
.footer__item-form-check {
  display: none;
}
.footer__item-form-check-box {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 12px;
}
.footer__item-form-check-box svg {
  overflow: visible;
}
.footer__item-form-check-box path {
  fill: none;
  stroke: white;
  stroke-width: 6;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: stroke-dasharray 0.5s ease, stroke-dashoffset 0.5s ease;
  -webkit-transition: stroke-dasharray 0.5s ease, stroke-dashoffset 0.5s ease;
  -moz-transition: stroke-dasharray 0.5s ease, stroke-dashoffset 0.5s ease;
  -o-transition: stroke-dasharray 0.5s ease, stroke-dashoffset 0.5s ease;
  transition: stroke-dasharray 0.5s ease, stroke-dashoffset 0.5s ease;
  stroke-dasharray: 241 9999999;
  stroke-dashoffset: 0;
}
.footer__item-form-check-box input:checked ~ svg path {
  stroke-dasharray: 70.51 9999999;
  stroke-dashoffset: -262.27;
}
.footer__item-form-check-text {
  color: var(--color-item-12);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.footer__item-form-submit {
  line-height: 20px;
  font-weight: 700;
  font-size: 16px;
  color: var(--color-item-0, #fff);
  padding: 19px 52px;
  background-color: var(--color-item-6, #ff9900);
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  text-align: center;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.footer__item-form-submit:hover, .footer__item-form-submit:focus {
  background-color: var(--color-item-7, #e68a00);
}
.footer__item-form-submit:active {
  background-color: var(--color-item-8, #cc6600);
}
@media (max-width: 1130px) {
  .footer__item:nth-child(3) {
    display: none;
  }
  .footer__item:nth-child(2) {
    max-width: 541px;
  }
  .footer__item-form-submit {
    padding: 19px 38px;
  }
}
@media (max-width: 920px) {
  .footer .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column-reverse;
    gap: 50px;
  }
  .footer__item-logo {
    margin-bottom: 16px;
  }
  .footer__item-link-list {
    display: none;
  }
}
@media (max-width: 740px) {
  .footer {
    padding: 18px 0;
  }
  .footer .container {
    gap: 17px;
  }
  .footer__item-logo {
    width: 130px;
  }
  .footer__item-icon {
    width: 40px;
    height: 40px;
  }
  .footer__item-icon-list {
    gap: 16px;
  }
  .footer__item:first-child {
    max-width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer__item:nth-child(2) {
    max-width: 100%;
    gap: 5px;
    min-width: 200px;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--color-item-10, #444);
  }
  .footer__item-form-input, .footer__item-form-input:nth-child(4) {
    font-size: 14px;
    padding-left: 20px;
    margin-bottom: 12px;
  }
  .footer__item-form-title {
    font-size: 24px;
  }
  .footer__item-form-check-box {
    gap: 8px;
    margin-bottom: 12px;
  }
  .footer__item-form-check-text {
    font-size: 12px;
  }
  .footer__item-form-submit {
    padding: 12px 28px;
    font-size: 12px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
  }
}

:root {
  --color-item-0: #fff;
  --color-item-1: #000;
  --color-item-2: #222;
  --color-item-3: #333;
  --color-item-4: #666;
  --color-item-5: #999;
  --color-item-6: #ff9900;
  --color-item-7: #ffb84d;
  --color-item-8: #e1670e;
  --color-item-9: #f7b045;
  --color-item-10: #414141;
  --color-item-11: #7c7c7c;
  --color-item-12: #cacaca;
  --color-item-13: #ececec;
  --color-item-14: #b7b7bf;
  --color-item-15: #e5e5e5;
  --size-standart: 17.7px;
  --size-working: 142px;
  --size-footer: 236px;
  --container: 75px;
}

@media (max-width: 1130px) {
  :root {
    --container: 50px;
  }
}
@media (max-width: 740px) {
  :root {
    --container: 15px;
  }
}
/* Header */
.header {
  box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.1);
  padding: 12px 0 14px 0;
  margin-bottom: 50px;
  border-radius: 0 0 30px 30px;
  -webkit-border-radius: 0 0 30px 30px;
  -moz-border-radius: 0 0 30px 30px;
}
.header .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.header__burger {
  display: none;
}
.header__logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 225px;
  width: 100%;
  height: 100%;
  outline: 2px solid transparent;
  outline-offset: 5px;
  padding-top: 2px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.header__logo:focus {
  outline-color: var(--color-item-6);
}
.header__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__nav-cont {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
}
.header__nav-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  list-style: none;
  padding: 0;
  gap: 85.5px;
}
.header__nav-item {
  font-size: 18px;
  font-weight: 600;
}
.header__nav-item a {
  border-bottom: 2px solid transparent;
  outline: 2px solid transparent;
  outline-offset: 2px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.header__nav-item a:hover {
  border-color: var(--color-item-6);
}
.header__nav-item a:focus {
  outline-color: var(--color-item-6);
}
.header__nav-item a:active {
  border-color: var(--color-item-8);
  outline-color: transparent;
  color: var(--color-item-8);
}
.header__search {
  opacity: 1;
  position: absolute;
  z-index: 18;
  background-color: var(--color-item-0);
  right: calc(var(--container) - 32px);
  top: -100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);
  padding: 18px 32px;
  align-items: center;
  border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  width: 100%;
  max-width: 400px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  gap: 4px;
}
.header__search.active {
  -webkit-transform: translateY(105px);
  -moz-transform: translateY(105px);
  transform: translateY(105px);
}
.header__search-input {
  border: none;
  color: var(--color-item-12);
  padding-bottom: 5px;
  border-bottom: 1px solid var(--color-item-12);
  outline: none;
  width: 80%;
}
.header__search-icon {
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding: 0;
  background: transparent;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  transition: all 0.5s ease-in-out;
}
.header__search-icon svg {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.header__search-icon svg rect {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  fill: var(--color-item-1);
}
.header__search-icon svg path {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  stroke: var(--color-item-1);
}
.header__search-icon:focus {
  outline-color: var(--color-item-6);
}
.header__search-icon:focus svg rect {
  fill: var(--color-item-1);
}
.header__search-icon:focus svg path {
  stroke: var(--color-item-1);
}
.header__search-icon:hover svg path {
  stroke: var(--color-item-6);
}
.header__search-icon:hover svg rect {
  fill: var(--color-item-6);
}
.header__search-close svg rect {
  fill: var(--color-item-12);
}
.header__search-close svg circle {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.header__search-close:hover svg rect {
  fill: var(--color-item-6);
}
.header__search-close:hover svg circle {
  stroke: var(--color-item-6);
}
.header__search-close:focus {
  outline-color: var(--color-item-6);
}
.header__search-close:focus svg rect {
  fill: var(--color-item-12);
}
.header__search-close:focus svg circle {
  stroke: var(--color-item-12);
}
.header__search-close:active svg rect {
  fill: var(--color-item-1);
}
.header__search-close:active svg circle {
  stroke: var(--color-item-1);
}
@media (max-width: 1130px) {
  .header {
    padding: 32px 0 26px 0;
    margin-bottom: 40px;
  }
  .header__search.active {
    -webkit-transform: translateY(95px);
    -moz-transform: translateY(95px);
    transform: translateY(95px);
  }
  .header__nav {
    position: absolute;
    left: -273px;
    margin: 0;
    top: 0;
    z-index: 10;
    background-color: var(--color-item-0);
    display: block;
    padding: 80px 55px 55px 55px;
    border-radius: 0 0 32px 0;
    -webkit-border-radius: 0 0 32px 0;
    -moz-border-radius: 0 0 32px 0;
    -webkit-transition: transform 0.5s ease;
    -moz-transition: transform 0.5s ease;
    -o-transition: transform 0.5s ease;
    transition: transform 0.5s ease;
  }
  .header__nav-list {
    flex-direction: column;
    gap: 60px;
  }
  .open .header__nav {
    -webkit-transform: translateX(273px);
    -moz-transform: translateX(273px);
    transform: translateX(273px);
  }
  .open .header__nav-list {
    display: flex;
    margin: 0;
  }
  .open .header__nav-item {
    margin: 0;
  }
  .open .header__nav-item a {
    font-size: 24px;
  }
  .header__logo {
    max-width: 150px;
  }
  .header__burger {
    margin-top: 3px;
    width: 30px;
    height: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    align-self: center;
    z-index: 16;
    border: none;
  }
  .header__burger span {
    position: absolute;
    top: 54%;
    left: 3%;
    width: 30px;
    height: 3px;
    background-color: #000;
    -webkit-transform: translateX(-30%);
    -moz-transform: translateX(-30%);
    transform: translateX(-30%);
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  .header__burger span:nth-child(1) {
    -webkit-transform: translateY(-14px);
    -moz-transform: translateY(-14px);
    transform: translateY(-14px);
  }
  .header__burger span:nth-child(2) {
    -webkit-transform: translateY(-4px);
    -moz-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  .header__burger span:nth-child(3) {
    -webkit-transform: translateY(6px);
    -moz-transform: translateY(6px);
    transform: translateY(6px);
  }
  .open .header__burger span:nth-child(1) {
    -webkit-transform: translateY(-4px) rotate(45deg);
    -moz-transform: translateY(-4px) rotate(45deg);
    transform: translateY(-4px) rotate(45deg);
  }
  .open .header__burger span:nth-child(2) {
    opacity: 0;
  }
  .open .header__burger span:nth-child(3) {
    -webkit-transform: translateY(-4px) rotate(-45deg);
    -moz-transform: translateY(-4px) rotate(-45deg);
    transform: translateY(-4px) rotate(-45deg);
  }
}
@media (max-width: 920px) {
  .header {
    margin-bottom: 40px;
    padding: 10px 0;
    border-radius: 0 0 15px 15px;
    -webkit-border-radius: 0 0 15px 15px;
    -moz-border-radius: 0 0 15px 15px;
  }
  .header .open .header__nav {
    -webkit-transform: translateX(273px);
    -moz-transform: translateX(273px);
    transform: translateX(273px);
  }
}
@media (max-width: 500px) {
  .header {
    margin-bottom: 30px;
    padding: 16px 0;
  }
  .header .header__search.active {
    -webkit-transform: translateY(75px);
    -moz-transform: translateY(75px);
    transform: translateY(75px);
  }
  .header__logo {
    width: 100%;
    max-width: 110px;
  }
  .header__search {
    left: 0;
    right: 0;
    max-width: 100%;
    top: -90px;
    opacity: 1;
    display: flex;
    width: 100%;
    border-radius: 0 0 15px 15px;
    -webkit-border-radius: 0 0 15px 15px;
    -moz-border-radius: 0 0 15px 15px;
    padding: 22px 15px;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }
  .header__search-input {
    width: 70%;
  }
  .header__nav {
    width: 100%;
    left: -100%;
    padding: 50px 0 50px 20px;
  }
  .header__nav-list {
    gap: 32px;
  }
  .open .header__nav {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    transform: translateX(100%);
  }
}

:root {
  --color-item-0: #fff;
  --color-item-1: #000;
  --color-item-2: #222;
  --color-item-3: #333;
  --color-item-4: #666;
  --color-item-5: #999;
  --color-item-6: #ff9900;
  --color-item-7: #ffb84d;
  --color-item-8: #e1670e;
  --color-item-9: #f7b045;
  --color-item-10: #414141;
  --color-item-11: #7c7c7c;
  --color-item-12: #cacaca;
  --color-item-13: #ececec;
  --color-item-14: #b7b7bf;
  --color-item-15: #e5e5e5;
  --size-standart: 17.7px;
  --size-working: 142px;
  --size-footer: 236px;
  --container: 75px;
}

@media (max-width: 1130px) {
  :root {
    --container: 50px;
  }
}
@media (max-width: 740px) {
  :root {
    --container: 15px;
  }
}
/* Hero */
.hero {
  position: relative;
  margin-bottom: 50px;
  overflow: hidden;
}
.hero .container {
  position: relative;
  overflow: hidden;
}
.hero__content {
  padding: 100px 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  z-index: 2;
  background: none;
  pointer-events: none;
}
.hero__title {
  margin: 0;
  margin-bottom: 24px;
  font-size: 70px;
  font-weight: 800;
  max-width: 837px;
  line-height: 85px;
  color: #fff;
  color: var(--color-item-0);
}
.hero__descr {
  margin: 0;
  margin-bottom: 90px;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  max-width: 790px;
  color: #fff;
  color: var(--color-item-0);
}
.hero__smeta {
  font-size: 23px;
  font-weight: 700;
  color: #fff;
  color: var(--color-item-0);
  background-color: #ff9900;
  background-color: var(--color-item-6);
  padding: 25px 39px;
  line-height: 29px;
  pointer-events: auto;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  text-align: center;
  outline: none;
  -webkit-transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}
.hero__smeta:hover, .hero__smeta:focus {
  background-color: #ffb84d;
  background-color: var(--color-item-7);
}
.hero__smeta:active {
  background-color: #e1670e;
  background-color: var(--color-item-8);
}
.hero__swiper {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 22px;
  -webkit-border-radius: 22px;
  -moz-border-radius: 22px;
}
.hero__swiper-cont {
  position: absolute;
  top: 0;
  width: calc(100% - var(--container) * 2);
  height: 100%;
  z-index: 1;
}
.hero__swiper-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  -webkit-transition: transform 0.3s ease;
  -moz-transition: transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  will-change: transform;
}
.hero__swiper-slide {
  width: 100%;
  flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.hero__swiper-slide:nth-child(1) {
  background: url(../img/hero__bg-img-1.png) no-repeat center/cover;
  background-image: -webkit-image-set(url(../img/hero__bg-img-1.png) 1x, url(../img/hero__bg-img-1.webp) 1x);
  background-image: image-set(url(../img/hero__bg-img-1.png) 1x, url(../img/hero__bg-img-1.webp) 1x);
}
.hero__swiper-slide:nth-child(2) {
  background: url(../img/hero__bg-img-2.png) no-repeat center/cover;
  background-image: -webkit-image-set(url(../img/hero__bg-img-2.png) 1x, url(../img/hero__bg-img-2.webp) 1x);
  background-image: image-set(url(../img/hero__bg-img-2.png) 1x, url(../img/hero__bg-img-2.webp) 1x);
}
.hero__swiper-slide:nth-child(3) {
  background: url(../img/hero__bg-img-3.png) no-repeat center/cover;
  background-image: -webkit-image-set(url(../img/hero__bg-img-3.png) 1x, url(../img/hero__bg-img-3.webp) 1x);
  background-image: image-set(url(../img/hero__bg-img-3.png) 1x, url(../img/hero__bg-img-3.webp) 1x);
}
.hero__swiper-pagination {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  display: flex;
  gap: 20px;
}
.hero__swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border: none;
  cursor: pointer;
  opacity: 0.6;
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.hero__swiper-pagination-bullet:hover, .hero__swiper-pagination-bullet:focus, .hero__swiper-pagination-bullet:active {
  opacity: 0.8;
}
.hero__swiper-pagination-bullet.active {
  opacity: 1;
}
@media (max-width: 1130px) {
  .hero {
    margin-bottom: 35px;
  }
  .hero__title {
    font-size: 60px;
    line-height: 73px;
    margin-bottom: 16px;
  }
  .hero__descr {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 60px;
  }
  .hero__swiper {
    border-radius: 32px;
    -webkit-border-radius: 32px;
    -moz-border-radius: 32px;
  }
  .hero__swiper-cont {
    width: calc(100% - var(--container) * 2);
  }
  .hero__content {
    padding: 160px 77px;
  }
}
@media (max-width: 920px) {
  .hero {
    margin-bottom: 35px;
  }
  .hero__content {
    padding: 160px 40px;
  }
  .hero__title {
    font-size: 50px;
    line-height: 60px;
  }
  .hero__descr {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 61px;
  }
}
@media (max-width: 740px) {
  .hero {
    margin-bottom: 60px;
  }
  .hero__title {
    font-size: 20px;
    line-height: normal;
    margin-bottom: 6px;
  }
  .hero__content {
    padding: 60px 20px 60px 21px;
    align-items: center;
    text-align: center;
  }
  .hero__swiper {
    border-radius: 17px;
    -webkit-border-radius: 17px;
    -moz-border-radius: 17px;
  }
  .hero__swiper-cont {
    width: calc(100% - var(--container) * 2);
  }
  .hero__swiper-pagination {
    bottom: 15px;
  }
  .hero__swiper-pagination-bullet {
    width: 10px;
    height: 10px;
  }
  .hero__descr {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 33px;
  }
  .hero__smeta {
    padding: 13px 28px;
    font-size: 12px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
  }
}

:root {
  --color-item-0: #fff;
  --color-item-1: #000;
  --color-item-2: #222;
  --color-item-3: #333;
  --color-item-4: #666;
  --color-item-5: #999;
  --color-item-6: #ff9900;
  --color-item-7: #ffb84d;
  --color-item-8: #e1670e;
  --color-item-9: #f7b045;
  --color-item-10: #414141;
  --color-item-11: #7c7c7c;
  --color-item-12: #cacaca;
  --color-item-13: #ececec;
  --color-item-14: #b7b7bf;
  --color-item-15: #e5e5e5;
  --size-standart: 17.7px;
  --size-working: 142px;
  --size-footer: 236px;
  --container: 75px;
}

@media (max-width: 1130px) {
  :root {
    --container: 50px;
  }
}
@media (max-width: 740px) {
  :root {
    --container: 15px;
  }
}
.how {
  margin-bottom: 111px;
}
.how__title {
  margin: 0 0 50px;
  /* Фолбэк для старых браузеров */
  color: #000;
  color: var(--color-item-1);
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.how__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 86px;
  margin-bottom: 46px;
}
@supports not (gap: 86px) {
  .how__nav > * {
    margin-right: 86px;
  }
  .how__nav > *:last-child {
    margin-right: 0;
  }
}
.how__nav-item {
  padding: 0;
}
.how__nav-item-btn {
  cursor: pointer;
  /* Фолбэк значения цвета */
  color: #000;
  color: var(--color-item-1);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  outline: 2px solid transparent;
  outline-offset: 2px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.how__nav-item-btn:hover {
  color: #ff9900;
  color: var(--color-item-6);
}
.how__nav-item-btn:focus {
  color: #000;
  color: var(--color-item-1);
  outline-color: #ff9900;
  outline-color: var(--color-item-6);
}
.how__nav-item-btn:active {
  color: #e1670e;
  color: var(--color-item-8);
  outline-color: transparent;
}
.how__nav-item-btn.active {
  color: #e1670e;
  color: var(--color-item-8);
  outline-color: transparent;
}
.how__page {
  /* Если браузер поддерживает Grid – используем его, иначе fallback на flex */
  opacity: 1;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
@supports (display: grid) {
  @supports (display: grid) {
    .how__page {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 30px;
    }
  }
  @supports not (display: grid) {
    .how__page {
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
    }
    .how__page > * {
      width: calc(100% / 2);
    }
  }
}
@supports not (display: grid) {
  .how__page {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }
}
.how__page:not(.active) {
  opacity: 0;
  display: none;
}
.how__page-btn-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
}
.how__page-img {
  height: 100%;
  padding-top: 10px;
}
.how__page-title {
  margin: 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 8px;
}
.how__page-descr {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  font-style: normal;
}
.how__page-btn {
  cursor: pointer;
  padding: 19px 37px;
  border: 1px solid transparent;
  border-radius: 15px;
  line-height: normal;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  outline: none;
}
.how__page-btn:first-child {
  color: #fff;
  color: var(--color-item-0);
  font-weight: 700;
  background-color: #ff9900;
  background-color: var(--color-item-6);
  border-color: #ff9900;
  border-color: var(--color-item-6);
}
.how__page-btn:first-child:hover, .how__page-btn:first-child:focus {
  background-color: #ff9900;
  background-color: var(--color-item-6);
  border-color: #ff9900;
  border-color: var(--color-item-6);
}
.how__page-btn:first-child:active {
  background-color: #e1670e;
  background-color: var(--color-item-8);
  border-color: #e1670e;
  border-color: var(--color-item-8);
}
.how__page-btn:last-child {
  color: #000;
  color: var(--color-item-1);
  font-weight: 400;
  border-color: #000;
  border-color: var(--color-item-1);
}
.how__page-btn:last-child:hover, .how__page-btn:last-child:focus {
  background-color: #666;
  background-color: var(--color-item-4);
  border-color: #666;
  border-color: var(--color-item-4);
  color: #fff;
  color: var(--color-item-0);
}
.how__page-btn:last-child:active {
  background-color: #000;
  background-color: var(--color-item-1);
  border-color: #000;
  border-color: var(--color-item-1);
  color: #fff;
  color: var(--color-item-0);
}
.how__page-btn-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  gap: 32px;
}
.how__page-text {
  border-radius: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  max-width: 758px;
  justify-content: space-between;
}
@media (max-width: 1130px) {
  .how {
    margin-bottom: 81px;
  }
  .how__title {
    font-size: 48px;
    line-height: 59px;
    margin-bottom: 50px;
  }
  .how__nav {
    margin-bottom: 50px;
  }
  .how__page-img {
    padding-top: 12px;
    padding-left: 10px;
  }
  .how__page-text {
    gap: 25px;
  }
}
@media (max-width: 920px) {
  .how {
    margin-bottom: 60px;
  }
  .how__nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }
  .how__title {
    font-weight: 400;
    font-size: 48px;
    line-height: 59px;
    margin-bottom: 48px;
  }
  .how__page {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column-reverse;
    gap: 39px;
  }
  .how__page-img {
    padding: 0;
    max-height: 284px;
    width: 100%;
  }
  .how br {
    display: none;
  }
}
@media (max-width: 480px) {
  .how {
    margin-bottom: 59px;
  }
  .how__title {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 20px;
  }
  .how__nav {
    margin-bottom: 16px;
  }
  .how__nav-item-btn {
    font-size: 14px;
  }
  .how__page {
    gap: 22px;
  }
  .how__page-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 8px;
  }
  .how__page-descr {
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 0;
  }
  .how__page-btn {
    border-radius: 10px;
  }
  .how__page-btn:first-child {
    padding: 11px 43px;
    font-size: 12px;
  }
  .how__page-btn:nth-child(2) {
    padding: 12px 31px;
    font-size: 12px;
  }
  .how__page-btn-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 9px;
  }
}

/* No styles code below. Only in modules */
/* Не пишите CSS код ниже. Только в подключаемых файлах */