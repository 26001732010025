@use '../base/vars' as vars;

.footer {
  /* Фолбэк для браузеров, не поддерживающих CSS-переменные */
  background-color: #333;
  background-color: var(--color-item-2, #333);
  padding: 50px 0;

  .container {
    @include flex-container;
    justify-content: space-between;
    color: var(--color-item-12, #ccc);
    gap: 32px;
  }

  &__item {
    @include flex-container;
    flex-direction: column;
    width: 100%;

    &:nth-child(1) {
      max-width: 252px;
      justify-content: space-between;
    }

    &:nth-child(2) {
      max-width: 570px;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 420px;
      gap: 25px;
    }

    &:nth-child(3) {
      text-align: start;
      justify-self: center;
      align-self: center;
      max-width: 363px;
      color: var(--color-item-12, #ccc);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
    }

    &-logo {
      margin-bottom: 19px;
    }

    &-link {
      a {
        color: var(--color-item-12, #ccc);
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px;
        outline: 1px solid transparent;
        outline-offset: 5px;
        @include transition(all, 0.2s, ease-in-out);

        &:hover {
          color: var(--color-item-6, #ff9900);
        }
        &:focus {
          color: var(--color-item-12, #ccc);
          outline-color: var(--color-item-6, #ff9900);
        }
        &:active {
          outline-color: transparent;
          color: var(--color-item-8, #cc6600);
        }
      }
      &-list {
        margin: 0;
        padding: 0;
        @include flex-container;
        flex-direction: column;
      }
    }

    &-icon {
      &-list {
        @include flex-container;
        align-items: center;
        gap: 25px;
      }
      @include flex-container;
      a {
        border: none;
        outline: 1px solid transparent;
        outline-offset: 5px;
        @include transition(all, 0.2s, ease-in-out);
        @include flex-container;

        svg {
          @include transition(all, 0.2s, ease-in-out);
          path {
            @include transition(all, 0.2s, ease-in-out);
            fill: var(--color-item-11, #aaa);
          }
        }
        &:hover {
          svg {
            fill: var(--color-item-6, #ff9900);
            path {
              fill: var(--color-item-6, #ff9900);
            }
          }
        }
        &:focus {
          outline-color: var(--color-item-6, #ff9900);
          svg {
            path {
              fill: var(--color-item-11, #aaa);
            }
          }
        }
        &:active {
          outline-color: transparent;
          svg {
            fill: var(--color-item-8, #cc6600);
            path {
              fill: var(--color-item-8, #cc6600);
            }
          }
        }
      }
    }

    &-form {
      &-title {
        margin: 0;
        font-weight: 400;
        font-size: 48px;
        line-height: 59px;
        text-align: start;
        width: 100%;
      }
      &-input {
        width: 100%;
        background-color: var(--color-item-10, #444);
        color: var(--color-item-12, #ccc);
        height: 60px;
        padding-left: 24px;
        border-radius: 15px;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        border: none;
        outline: none;
        @include transition(all, 0.2s, ease-in-out);

        &:nth-child(4) {
          height: 150px;
          padding-top: 15px;
          line-height: 32px;
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 7px;
        }
        &:hover,
        &:focus {
          background-color: var(--color-item-4, #555);
          color: var(--color-item-12, #ccc);
        }
        &:active {
          background-color: var(--color-item-4, #555);
          color: var(--color-item-0, #fff);
        }
      }
      &-check {
        &-box {
          cursor: pointer;
          @include flex-container;
          align-items: center;
          gap: 12px;
          svg {
            overflow: visible;
          }
          path {
            fill: none;
            stroke: white;
            stroke-width: 6;
            stroke-linecap: round;
            stroke-linejoin: round;
            transition: stroke-dasharray 0.5s ease, stroke-dashoffset 0.5s ease;
            -webkit-transition: stroke-dasharray 0.5s ease,
              stroke-dashoffset 0.5s ease;
            -moz-transition: stroke-dasharray 0.5s ease,
              stroke-dashoffset 0.5s ease;
            -o-transition: stroke-dasharray 0.5s ease,
              stroke-dashoffset 0.5s ease;
            transition: stroke-dasharray 0.5s ease, stroke-dashoffset 0.5s ease;
            stroke-dasharray: 241 9999999;
            stroke-dashoffset: 0;
          }
          & input:checked ~ svg path {
            stroke-dasharray: 70.51 9999999;
            stroke-dashoffset: -262.27;
          }
        }
        display: none;
        &-text {
          color: var(--color-item-12);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
        }
      }
      &-submit {
        line-height: 20px;
        font-weight: 700;
        font-size: 16px;
        color: var(--color-item-0, #fff);
        padding: 19px 52px;
        background-color: var(--color-item-6, #ff9900);
        border-radius: 15px;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        text-align: center;
        outline: none;
        @include transition(all, 0.2s, ease-in-out);

        &:hover,
        &:focus {
          background-color: var(--color-item-7, #e68a00);
        }
        &:active {
          background-color: var(--color-item-8, #cc6600);
        }
      }
    }
  }

  @media (max-width: 1130px) {
    &__item {
      &:nth-child(3) {
        display: none;
      }
      &:nth-child(2) {
        max-width: 541px;
      }
      &-form {
        &-submit {
          padding: 19px 38px;
        }
      }
    }
  }

  @media (max-width: 920px) {
    .container {
      @include flex-container;
      flex-direction: column-reverse;
      gap: 50px;
    }
    &__item {
      &-logo {
        margin-bottom: 16px;
      }
      &-link {
        &-list {
          display: none;
        }
      }
    }
  }

  @media (max-width: 740px) {
    padding: 18px 0;
    .container {
      gap: 17px;
    }
    &__item {
      &-logo {
        width: 130px;
      }
      &-icon {
        width: 40px;
        height: 40px;
        &-list {
          gap: 16px;
        }
      }
      &:first-child {
        max-width: 100%;
        @include flex-container;
        flex-direction: column;
        align-items: center;
      }
      &:nth-child(2) {
        max-width: 100%;
        gap: 5px;
        min-width: 200px;
        padding-bottom: 16px;
        border-bottom: 1px solid var(--color-item-10, #444);
      }
      &-form {
        &-input,
        &-input:nth-child(4) {
          font-size: 14px;
          padding-left: 20px;
          margin-bottom: 12px;
        }
        &-title {
          font-size: 24px;
        }
        &-check {
          &-box {
            gap: 8px;
            margin-bottom: 12px;
          }
          &-text {
            font-size: 12px;
          }
        }
        &-submit {
          padding: 12px 28px;
          font-size: 12px;
          border-radius: 10px;
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
        }
      }
    }
  }
}
