@charset "UTF-8";
.ac {
  margin-top: 8px;
  border: 1px solid #eee;
  background-color: #fff;
  box-sizing: border-box;
}
.ac .ac-header {
  margin: 0;
  padding: 0;
}
.ac .ac-trigger {
  color: #111;
  text-align: left;
  width: 100%;
  padding: 8px 32px 8px 8px;
  display: block;
  cursor: pointer;
  background-color: transparent;
  transition: color 0.25s ease;
  position: relative;
  text-decoration: none;
  margin: 0;
  border: 0;
}
.ac .ac-trigger::after {
  text-align: center;
  width: 15px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  position: absolute;
  right: 10px;
  top: 50%;
}
.ac .ac-trigger:focus {
  color: #8a8a8a;
}
.ac .ac-panel {
  overflow: hidden;
  transition-property: height, visibility;
  transition-timing-function: ease;
}
.ac .ac-panel .ac-text {
  color: #111;
  padding: 8px;
  margin: 0;
}
.ac.js-enabled .ac-panel {
  visibility: hidden;
}
.ac.is-active .ac-panel {
  visibility: visible;
}
.ac.is-active > .ac-header .ac-trigger::after {
  content: '–';
}
