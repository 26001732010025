@use '../base/vars' as vars;
.about {
  margin-bottom: 100px;

  &__title {
    margin: 0 0 20px;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__descr {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    flex-basis: 450px;
    margin-bottom: 50px;
    max-width: 1235px;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    gap: 32px 34px;

    @supports (display: grid) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }

    &-item {
      border-radius: 30px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      grid-column: span 1;
      gap: 9px;

      &-title {
        margin: 0;
        font-size: 24px;
        color: #333;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      &:first-child {
        width: 100%;
        grid-row: span 2;
        @include flex-container;
        flex-direction: column;
        justify-content: flex-end;
        background: url(../../img/about__block-bg-img.png) no-repeat
          center/cover;
        background-image: -webkit-image-set(
          url(../../img/about__block-bg-img.png) 1x,
          url(../../img/about__block-bg-img.webp) 1x
        );
        background-image: image-set(
          url(../../img/about__block-bg-img.png) 1x,
          url(../../img/about__block-bg-img.webp) 1x
        );
        height: 100%;
        color: var(--color-item-0, #fff);

        .about__content-item-descr {
          letter-spacing: 0;
          max-width: 435px;
        }
      }

      &-descr {
        margin: 0;
        font-size: 16px;
        line-height: 32px;
        letter-spacing: -0.5px;
        font-style: normal;
        font-weight: 700;
      }

      &-text {
        min-height: 196px;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 32px;
        -webkit-border-radius: 32px;
        -moz-border-radius: 32px;
        @include flex-container;
        align-items: center;
        justify-content: space-between;
        padding: 50px;
        gap: 16px;
      }

      &:not(:first-child) {
        @include flex-container;
        flex-direction: column;
        justify-content: space-between;
        background-color: var(--color-item-0, #fff);
        border: 1px solid var(--color-item-12, #ccc);
        border-radius: 32px;
        -webkit-border-radius: 32px;
        -moz-border-radius: 32px;
        padding: 64px 60px 63px 297px;
        background-position: 57px center;
        background-repeat: no-repeat;
      }

      &:nth-child(2) {
        background-image: url(../../img/about__block-icon-1.svg);
      }

      &:nth-child(3) {
        background-image: url(../../img/about__block-icon-2.svg);
      }

      &-btn {
        cursor: pointer;
        color: var(--color-item-0, #fff);
        background-color: transparent;
        border: 3px solid var(--color-item-6, #ff9900);
        padding: 18px 35px;
        border-radius: 16px;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        outline: none;
        @include transition(all, 0.5s, ease-in-out);
        font-weight: 700;

        &:hover,
        &:focus {
          border-color: var(--color-item-6, #ff9900);
          background-color: var(--color-item-6, #ff9900);
          color: var(--color-item-0, #fff);
        }
        &:active {
          border-color: var(--color-item-8, #cc6600);
          background-color: var(--color-item-8, #cc6600);
          color: var(--color-item-0, #fff);
        }
        &:focus {
          /* Альтернативный индикатор фокуса для доступности */
          outline: none;
          box-shadow: 0 0 0 3px rgba(255, 153, 0, 0.5);
        }
      }
    }
  }

  // Медиа-запросы

  @media (max-width: 1590px) {
    &__content {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 304px) repeat(2, 295px);
      gap: 32px 0;

      &-item {
        &:first-child {
          .about__content-item-descr {
            max-width: 660px;
          }
        }
        &-descr {
          max-width: 450px;
        }
        &:not(:first-child) {
          padding: 64px 60px 63px 316px;
          background-position: 76px center;
          justify-content: start;
        }
      }
    }
  }

  @media (max-width: 1130px) {
    margin-bottom: 80px;

    &__title {
      font-size: 48px;
      line-height: 59px;
    }

    &__descr {
      padding: 0;
      max-width: 924px;
      width: 100%;
      margin-bottom: 60px;
    }

    &__content {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 304px) repeat(2, 295px);
      gap: 32px 0;

      &-item {
        &-descr {
          max-width: 450px;
        }
        &:not(:first-child) {
          padding: 64px 60px 63px 316px;
          background-position: 76px center;
          justify-content: start;
        }
      }
    }
  }

  @media (max-width: 920px) {
    &__descr {
      margin-bottom: 55px;
    }
    &__content {
      grid-template-rows: repeat(2, 309px) repeat(2, 235px);

      &-item {
        &-text {
          padding: 35px 40px 34px 40px;
        }
        &:not(:first-child) {
          gap: 8px;
          padding: 34px 40px 35px 169px;
          background-size: 114px;
          background-position: 39px center;
        }
      }
    }
  }

  @media (max-width: 740px) {
    margin-bottom: 60px;

    &__title {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 20px;
    }
    &__descr {
      font-size: 14px;
      line-height: 28px;
      margin-bottom: 14px;
      max-width: 100%;
      width: 100%;
    }
    &__content {
      display: flex;
      flex-direction: column;

      &-item {
        border-radius: 0;
        padding: 0;

        &:first-child {
          background-size: contain;
          background-position: top;
          background-size: 100% 200px;
          .about__content-item-descr {
            color: var(--color-item-1);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
          }
        }
        &:not(:first-child) {
          padding: 61px 0 0 0;
          gap: 5px;
          border: none;
          background-position: 0 1px;
          background-size: 68px;
        }
        &-title {
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        &-descr {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
          max-width: 100%;
          width: 100%;
        }
        &-btn {
          padding: 10px 42px;
          color: var(--color-item-6);
          text-align: center;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          border-width: 2px;
          border-radius: 8px;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
        }
        &-text {
          background: transparent;
          padding: 0;
          @include flex-container;
          flex-direction: column;
          padding-top: 207px;
          align-items: start;
          gap: 12px;
        }
      }
    }
  }
}
