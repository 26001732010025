@use '../base/vars' as vars;
.faq {
  margin-bottom: 100px;

  &__title {
    margin: 0 0 32px 0;
    color: $color-item-1;
    color: var(--color-item-1);
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__item {
    @include transition(all, 0.5s, ease-in-out);
    border-bottom: 1px solid var(--color-item-12);

    &:first-child {
      border-top: 1px solid var(--color-item-12);
    }

    &-list {
      @include flex-container;
      flex-direction: column;
    }

    &-header {
      @include flex-container;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      width: 100%;
      background: none;
      padding: 30px 0;
      @include transition(all, 0.5s, ease-in-out);
      outline: none;
      gap: 16px;

      &-title {
        color: $color-item-1;
        color: var(--color-item-1);
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        text-align: start;
        line-height: 32px;
        @include transition(all, 0.5s, ease-in-out);
      }

      svg {
        min-width: 50px;
        min-height: 50px;
        @include transition(all, 0.5s, ease-in-out);
        ellipse {
          @include transition(all, 0.5s, ease-in-out);
          fill: var(--color-item-12);
        }
        path {
          @include transition(all, 0.5s, ease-in-out);
          fill: var(--color-item-1);
        }
      }

      &:hover {
        svg {
          ellipse {
            fill: var(--color-item-6);
          }
          path {
            fill: var(--color-item-0);
          }
        }
        .faq__item-header-title {
          color: var(--color-item-6);
        }
      }

      &:focus {
        svg {
          ellipse {
            fill: var(--color-item-8);
          }
          path {
            fill: var(--color-item-0);
          }
        }
        .faq__item-header-title {
          color: var(--color-item-8);
        }
      }
    }

    &-panel {
      overflow: hidden;
      max-height: 0;
      @include transition(all, 0.5s, ease-in-out);

      &-text {
        margin: 0 0 16px;
        @include flex-container;
        flex-direction: column;
        gap: 32px;
        color: var(--color-item-1);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &.active {
      svg {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      .faq__item-panel {
        max-height: 1000px;
        margin-bottom: 40px;
      }
    }
  }

  @media (max-width: 1130px) {
    margin-bottom: 80px;

    &__item {
      &-header {
        &-title {
          font-size: 20px;
          text-align: start;
        }
      }
    }
  }

  @media (max-width: 920px) {
    margin-bottom: 111px;

    &__title {
      margin-bottom: 33px;
    }
  }

  @media (max-width: 740px) {
    margin-bottom: 60px;

    &__title {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 16px;
    }
    &__item {
      &-header {
        &-title {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0.17px;
        }
        svg {
          min-width: 30px;
          max-width: 30px;
        }
      }
      &-panel {
        &-text {
          font-size: 12px;
          line-height: 24px;
        }
      }
    }
  }
}
